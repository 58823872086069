<template>
  <div id="component-tower-details-3d-visualisation" class="w-100 bg-light">
    <iframe
      :src="tower.modelUrl"
      frameborder="0"
      class="d-block w-100 h-100"
      @load="$parent.isLoading = false"
    ></iframe>
    <div id="component-tower-details-inset-menu" :class="{ zoomed: isZoomed }">
      <h2>
        <i class="fas fa-pencil-ruler mr-2"></i>
        Original Drawing
      </h2>
      <img :src="tower.tower.ancillariesLayoutImage.url" @click="zoomImage()" />
    </div>
  </div>
</template>

<script>
export default {
  name: "towerDetails3DVisualisation",
  props: ["tower"],
  data() {
    return {
      isZoomed: false,
    };
  },
  methods: {
    zoomImage() {
      this.isZoomed = !this.isZoomed;
    },
  },
  mounted() {
    this.$parent.isLoading = true;
  },
};
</script>